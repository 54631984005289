<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<GalleryModalProps>(), {
  slides: []
})
import { toRefs as __MACROS_toRefs } from "vue";
import {contentTypeOfMediaFile} from "~/utils/files";

interface GalleryModalProps {
  slides?: string[]
  index: Ref<number>
  onNext?: any
  onPrev?: any
  onGoTo?: any
}

const {
  slides,
  index
} = __MACROS_toRefs(__MACROS_props);

const emit = defineEmits<{
  (e: 'next'): void
  (e: 'prev'): void
  (e: 'go-to'): void
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const onGoTo = (index: number) => {
  emit('go-to', index)
}

const getSlideSrc = (slide: any) => {
  if (slide?.src) {
    return slide.src;
  }

  return slide;
};

const currentSlide = computed(() => {
  const item = slides.value?.[index.value];

  return getSlideSrc(item);
});

const sourceType = computed(()=>{
  const fileSrc = unref(currentSlide)
  return contentTypeOfMediaFile(fileSrc);
})

</script>

<template>
  <div
    class="gallery-slideshow__overlay"
  >
    <button
      class="close"
      @click="emit('close')"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4081 3.41559C20.189 2.6347 20.189 1.36655 19.4081 0.585663C18.6272 -0.195221 17.359 -0.195221 16.5782 0.585663L10 7.17008L3.41559 0.59191C2.6347 -0.188974 1.36655 -0.188974 0.585663 0.59191C-0.195221 1.37279 -0.195221 2.64095 0.585663 3.42183L7.17008 10L0.59191 16.5844C-0.188974 17.3653 -0.188974 18.6335 0.59191 19.4143C1.37279 20.1952 2.64095 20.1952 3.42183 19.4143L10 12.8299L16.5844 19.4081C17.3653 20.189 18.6335 20.189 19.4143 19.4081C20.1952 18.6272 20.1952 17.359 19.4143 16.5782L12.8299 10L19.4081 3.41559Z"
          fill="#1F163B"
        />
      </svg>
    </button>
    <div
      ref="zoomEl"
      class="gallery-slideshow__content"
    >
      <CarouselFullscreenLayout @close="emit('close')">
        <template #default>
          <CarouselControls
            :slides="slides"
            @next="emit('next')"
            @prev="emit('prev')"
          >
            <template v-if="sourceType === 'image'">
              <NuxtImg
                v-if="currentSlide"
                :src="currentSlide"
                format="webp"
                quality="65"
                width="1900"
                fit="contain"
                placeholder
                @click="emit('close')"
              />
            </template>
            <template v-if="sourceType === 'video'">
              <video
                class="video-player"
                :src="currentSlide"
                controls
              />
            </template>
          </CarouselControls>
        </template>
        <template #footer>
          <CarouselItems
            :slides="slides"
            @go-to-slide="onGoTo"
          />
        </template>
      </CarouselFullscreenLayout>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-player{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
