
const fileTypes: Record<string, string> = {
  'jpg': 'image',
  'jpeg': 'image',
  'png': 'image',
  'bmp': 'image',
  'mp4': 'video',
}

export const contentTypeOfMediaFile = (src: MaybeRef<string>) => {
  const fileSrc = unref(src)
  const ext = fileSrc?.split('.').pop()
  if (!ext) {
    return 'image'
  }
  return fileTypes[ext] || 'image';
}


