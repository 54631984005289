<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<CarouselItems>(), {
    slides: [],
  })
import { toRefs as __MACROS_toRefs } from "vue";
import {contentTypeOfMediaFile} from "~/utils/files";
interface CarouselItems {
  slides: string[]
}

const { slides } = __MACROS_toRefs(__MACROS_props)

const emit = defineEmits(['goToSlide'])

const getSlideSrc = (slide: any) => {
  if (slide?.src){
    return slide.src
  }

  return slide
}

const getSourceType = (src) => {
  return contentTypeOfMediaFile(src);
}

const slidesComputed = computed(()=>{
  return unref(slides)?.map((slide, index) => {
    const src = getSlideSrc(slide)
    return {
      index,
      src: src,
      slide: src,
      srcType: getSourceType(src),
    }
  })
})

</script>
<template>
  <div
    v-if="slidesComputed.length"
    class="slider__list"
  >
    <div
      class="slider__list-inner"
      :style="{
        // width: slides.length * 50 + 'px',
        width: `calc((${slidesComputed.length} * var(--slider-image-width)) + (${slidesComputed.length - 2} * var(--slider-image-gap)))`,
      }"
    >
      <button
        v-for="(item,index) in slidesComputed"
        :key="item.src"
        class="slider__list-item"
        @click="emit('goToSlide',index)"
      >
        <template v-if="item.srcType === 'image'">
          <NuxtImg
            :src="item.src"
            preset="sliderThumbnail"
            placeholder
            alt=""
          />
        </template>
        <template v-if="item.srcType === 'video'">
          <AppVideoFrame :src="item.src" />
        </template>
      </button>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.slider__list {
  --slider-image-width: 76px;
  --slider-image-gap: 10px;

  overflow-x: scroll;
  overflow-y: hidden;

  &-inner {
    display: flex;
  }

  position: relative;
  display: flex;
  align-items: center;
  height: 100px;
  margin-bottom: 20px;

  //&::after, &::before {
  //  position: absolute;
  //  content: "";
  //  bottom: -13px;
  //  left: 0;
  //  width: 100%;
  //  height: 2px;
  //  background: linear-gradient(58.17deg, #2D2D86 0.79%, #F0494F 100%);
  //  border-radius: 5px;
  //  cursor: pointer;
  //}

  &::before {
    z-index: 1;
    bottom: -12px;
    width: 137px;
    height: 5px;
    transform: translateY(50%);
  }

  &-item {
    display: inline-block;
    align-items: center;
    justify-content: center;
    //width: 76px;
    height: 76px;
    width: var(--slider-image-width);
    //width: 20%;
    border-radius: 5px;
    overflow: hidden;
    margin-right: calc(var(--slider-image-gap) / 2);

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
