<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<VideoFrameProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";

interface VideoFrameProps {
  src: string;
}

const {src} = __MACROS_toRefs(__MACROS_props)

const imageData = ref(null);

const cachedImages = useState('video-frames-cache', () => ({}))

async function getFirstFrame(videoUrl: string): Promise<string> {
  if (cachedImages.value[videoUrl]){
    return cachedImages.value[videoUrl];
  }
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.playsInline = true;

    video.addEventListener("loadeddata", () => {
      video.currentTime = 0;
    });

    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = canvas.toDataURL("image/png");
        cachedImages.value[videoUrl] = imageData;
        resolve(imageData);
      } else {
        reject("Canvas context not available");
      }
    });

    video.addEventListener("error", () => {
      reject("Error loading video");
    });

    video.load();
  });
}


tryOnMounted(async()=> {
  const frame = await getFirstFrame(src.value);
  imageData.value = frame;
})


</script>

<template>
  <ClientOnly>
    <NuxtImg
      v-if="imageData"
      :src="imageData"
    />
  </ClientOnly>
</template>

<style scoped lang="scss">

</style>
