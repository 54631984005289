<script setup lang="ts">
const __MACROS_emit = defineEmits(["close"])

const onClose = (...args) => __MACROS_emit("close", ...args)
</script>

<template>
  <div class="fullscreen-layout">
    <div class="fullscreen-container">
      <div
        class="main"
        @click="onClose"
      >
        <slot />
      </div>
      <div class="footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fullscreen-container {
  display: grid;
  grid-template-rows: calc(100vh - 100px) 130px;
  width: 100vw;
  height: 100%;
}

.main {
  display: grid;
  place-items: center;
  overflow: auto;
  height: calc(100vh - 100px);
  :deep(img) {
    height: calc(100vh - 120px);
    object-fit: contain;
    margin-top: 10px;
  }
}

.footer {
  max-height: 100px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
